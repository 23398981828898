<template>
    <v-container class="ampifire-banner rounded-lg">
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header class="font-weight-bold">
                    This amp is ready for you to publish. Here’s what to do...
                </v-expansion-panel-header>
                <v-expansion-panel-content
                    class="ampifire-banner-info"
                    :class="{
                        'pt-7': $vuetify.breakpoint.smAndDown,
                        'pt-0': $vuetify.breakpoint.mdAndUp
                    }"
                >
                    <v-row class="pb-7">
                        <div
                            class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12"
                        >
                            <v-row>
                                <v-col cols="1">
                                    <div
                                        class="px-3 py-1 rounded-circle d-inline-block add-letter-spacing instruction-number"
                                    >
                                        1
                                    </div>
                                </v-col>
                                <v-col cols="9" class="ml-5 pl-1 mr-0 pr-0">
                                    Review the content of each tab below.
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="1">
                                    <div
                                        class="px-3 py-1 rounded-circle d-inline-block instruction-number"
                                    >
                                        2
                                    </div>
                                </v-col>
                                <v-col cols="9" class="ml-5 pl-1 mr-0 pr-0">
                                    Action anything in red in the right sidebar.
                                </v-col>
                            </v-row>
                        </div>
                        <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12"
                        >
                            <v-row>
                                <v-col cols="1">
                                    <div
                                        class="px-3 py-1 rounded-circle d-inline-block instruction-number"
                                    >
                                        3
                                    </div>
                                </v-col>
                                <v-col cols="10" class="ml-5 pl-1 mr-0 pr-0">
                                    You can make changes if you wish. If you
                                    want our authors to change something, send
                                    it back to them by submitting a comment.
                                </v-col>
                            </v-row>
                        </div>
                        <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12"
                        >
                            <v-row>
                                <v-col cols="1">
                                    <div
                                        class="px-3 py-1 rounded-circle d-inline-block instruction-number"
                                    >
                                        4
                                    </div>
                                </v-col>
                                <v-col cols="10" class="ml-5 pl-2 mr-0 pr-0">
                                    If you are satisfied with the content, click
                                    “Go to Publish” above to approve all
                                    modules.
                                </v-col>
                            </v-row>
                        </div>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import ABanner from '@/components/ABanner';

@Component({
    components: {
        ABanner
    }
})
export default class AuthorAmpBanner extends Vue {}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.ampifire-banner {
    .ampifire-banner--content {
        position: relative;
        z-index: 1;
    }

    .ampifire-banner-info {
        position: relative;
        @media #{map-get($display-breakpoints, 'md-and-up')} {
            background-image: url('@/assets/img/question-background.svg');
        }
        background-size: contain;
        background-position-x: right;
        background-repeat: no-repeat;
    }

    .add-letter-spacing {
        letter-spacing: 3px; /*making characters with the same occupied size.*/
    }

    .instruction-number {
        color: $secondary-color;
        background-color: $blueish;
    }
}
</style>
