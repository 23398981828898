import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"ampifire-banner rounded-lg"},[_c(VExpansionPanels,[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"font-weight-bold"},[_vm._v(" This amp is ready for you to publish. Here’s what to do... ")]),_c(VExpansionPanelContent,{staticClass:"ampifire-banner-info",class:{
                    'pt-7': _vm.$vuetify.breakpoint.smAndDown,
                    'pt-0': _vm.$vuetify.breakpoint.mdAndUp
                }},[_c(VRow,{staticClass:"pb-7"},[_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12"},[_c(VRow,[_c(VCol,{attrs:{"cols":"1"}},[_c('div',{staticClass:"px-3 py-1 rounded-circle d-inline-block add-letter-spacing instruction-number"},[_vm._v(" 1 ")])]),_c(VCol,{staticClass:"ml-5 pl-1 mr-0 pr-0",attrs:{"cols":"9"}},[_vm._v(" Review the content of each tab below. ")])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"1"}},[_c('div',{staticClass:"px-3 py-1 rounded-circle d-inline-block instruction-number"},[_vm._v(" 2 ")])]),_c(VCol,{staticClass:"ml-5 pl-1 mr-0 pr-0",attrs:{"cols":"9"}},[_vm._v(" Action anything in red in the right sidebar. ")])],1)],1),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12"},[_c(VRow,[_c(VCol,{attrs:{"cols":"1"}},[_c('div',{staticClass:"px-3 py-1 rounded-circle d-inline-block instruction-number"},[_vm._v(" 3 ")])]),_c(VCol,{staticClass:"ml-5 pl-1 mr-0 pr-0",attrs:{"cols":"10"}},[_vm._v(" You can make changes if you wish. If you want our authors to change something, send it back to them by submitting a comment. ")])],1)],1),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12"},[_c(VRow,[_c(VCol,{attrs:{"cols":"1"}},[_c('div',{staticClass:"px-3 py-1 rounded-circle d-inline-block instruction-number"},[_vm._v(" 4 ")])]),_c(VCol,{staticClass:"ml-5 pl-2 mr-0 pr-0",attrs:{"cols":"10"}},[_vm._v(" If you are satisfied with the content, click “Go to Publish” above to approve all modules. ")])],1)],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }