import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('amp-module-header',{attrs:{"amp-id":_vm.announcementId},on:{"ready":_vm.onModuleReady,"publish":_vm.publish},scopedSlots:_vm._u([{key:"default",fn:function({ create, reload, setPrePublishHook }){return [(_vm.canAccessAmp)?_c(VContainer,{class:{
                'pt-10 pb-6': _vm.$vuetify.breakpoint.mdAndUp,
                'px-0': _vm.$vuetify.breakpoint.smAndDown
            }},[_c(VRow,[(_vm.isAuthoredAmp)?_c('author-amp-banner'):_vm._e(),_c(VCol,{attrs:{"md":"8"}},[_c(VTabs,{staticClass:"module-tabs",attrs:{"background-color":"main-background","slider-size":"4","show-arrows":"","fixed-tabs":"","centered":""}},_vm._l((_vm.tabsAdjusted),function(tab){return _c(VTab,{key:tab.label,staticClass:"text-capitalize module-tab px-2",attrs:{"to":_vm.fullify(tab.to),"disabled":tab.disabled}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(_vm._s(tab.icon))]),_vm._v(" "+_vm._s(tab.label)+" ")],1)}),1)],1),_c(VCol,{staticClass:"d-flex align-center",class:{ 'pa-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"md":"4"}},[_c('amp-module-link',{attrs:{"links":_vm.moduleLinks,"warn":_vm.hasChecksum}})],1)],1)],1):_vm._e(),(_vm.canAccessAmp)?_c('router-view',{on:{"create":create,"reload":reload,"pre-publish":setPrePublishHook,"links":_vm.setModuleLinks}}):_c(VContainer,{staticClass:"pa-0 pt-4"},[_c(VRow,[_c(VCol,{staticClass:"mx-n1"},[_c(VCardTitle,[_c(VSkeletonLoader,{attrs:{"loading":"","type":"heading","width":"75%"}})],1),_c(VCardText,[_c(VSkeletonLoader,{attrs:{"loading":"","type":"paragraph"}})],1)],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }